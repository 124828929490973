import * as React from "react"
import { styled } from "../stitches.config"
import { IPost } from "../types/post"
import Flex from "./Flex"
import Link from "./Link"
import Tag from "./Tag"
import Text from "./Text"

export interface PostItemProps {
    item: IPost
}

const Excerpt = styled(Text, {
    maxWidth: "$full",
    "@bp2": {
        maxWidth: "76%",
    },
})

export default function PostItem({
    item: {
        id,
        excerpt,
        timeToRead,
        frontmatter: { title, author, date, tags },
        slug,
    },
}: PostItemProps) {
    return (
        <Flex direction="column" gap="1">
            <Text as="h2" css={{ display: "inline-block" }} size="heading-03">
                <Link to={`/${slug}`}>{title} &#x27F6;</Link>
            </Text>
            {date && (
                <Text size="label-01" color="gray">
                    {date}
                </Text>
            )}
            {excerpt && <Excerpt size="body-01">{excerpt}</Excerpt>}
            {tags && (
                <Flex gap="1">
                    {tags.map((tag) => (
                        <Tag key={tag}>{tag}</Tag>
                    ))}
                </Flex>
            )}
        </Flex>
    )
}
