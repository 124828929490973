import * as React from "react"
import { IPost } from "../types/post"
import Flex from "./Flex"
import PostItem from "./PostItem"

export interface PostListProps {
    posts: IPost[]
    gap?: "1" | "2" | "3" | "4" | "5"
}

export default function PostList({ posts, gap = "4" }: PostListProps) {
    return (
        <Flex direction="column" gap={gap}>
            {posts.map((post) => (
                <PostItem key={post.id} item={post} />
            ))}
        </Flex>
    )
}
