import { graphql } from "gatsby"
import * as React from "react"
import A from "../components/A"
import Link from "../components/Link"
import PostList from "../components/PostList"
import Section from "../components/Section"
import SEO from "../components/SEO"
import Text from "../components/Text"
import { styled } from "../stitches.config"
import { IPost } from "../types/post"
import { github, linkedin } from "../util/socialLinks"

const SectionHeading = styled(Text, {
    mb: "$2",
    color: "$mauve9",
})

interface IndexPageProps {
    data: {
        posts: { edges: { node: IPost }[] }
    }
}

const IndexPage = ({
    data: {
        posts: { edges },
    },
}: IndexPageProps) => {
    return (
        <>
            <SEO title="Home" />
            <Section>
                <Text size="heading-03">
                    Hello, I'm Robert 👋. A software engineer from the
                    Netherlands. I like functional programming, mathematics and
                    minimal design. I'm the co-founder of{" "}
                    <A target="_blank" href="https://fulljoin.nl">
                        Full Join
                    </A>
                    , a data and software-consultancy agency. Full Join helps
                    businesses become fully data-driven, supports organisations
                    with hands-on functional programming in Rust and Haskell and
                    works on formal verification for web3 projects.
                    <br />
                    <br />
                    When I'm not programming, I create funky music with
                    synthesizers/vintage-organs or I'm travelling/camping in my
                    old 'krankenwagen' 🚑 &mdash; that's German for an
                    ambulance.
                </Text>
            </Section>

            <Section>
                <SectionHeading size="heading-01" as="h2">
                    Contact Me
                </SectionHeading>
                <Text size="heading-03">
                    I ❤️ email, seriously, don't hessitate to send me one at:{" "}
                    <A href="mailto:hello@robhar.com">hello@robhar.com</A>. If
                    you want to be secure, you can find my{" "}
                    <Link to="/pages/pgp">PGP</Link> 🔑 here. You can find me on{" "}
                    <A target="_blank" href={github}>
                        Github
                    </A>
                    ,{" "}
                    <A target="_blank" href={linkedin}>
                        Linkedin
                    </A>{" "}
                    here.
                </Text>
            </Section>

            {edges.length > 0 && (
                <Section>
                    <SectionHeading size="heading-01" as="h2">
                        Latest Posts
                    </SectionHeading>
                    <PostList posts={edges.map((x) => x.node)} gap="3" />
                </Section>
            )}
        </>
    )
}

export default IndexPage

export const pageQuery = graphql`
    query {
        posts: allMdx(
            filter: {
                frontmatter: { type: { eq: "post" }, published: { eq: true } }
            }
            sort: { order: DESC, fields: frontmatter___date }
            limit: 2
        ) {
            edges {
                node {
                    id
                    excerpt(truncate: true, pruneLength: 140)
                    frontmatter {
                        author
                        date(fromNow: true)
                        tags
                        title
                    }
                    timeToRead
                    headings(depth: h1) {
                        value
                        depth
                    }
                    slug
                }
            }
        }
    }
`
